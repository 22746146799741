.orderCard {
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 0px 0px 10px 0px rgb(120, 197, 244, 0.2);
  border: 1px solid #46a0d7;
  border-radius: 9px;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

@media (max-width: 900px) {
  .orderCard {
    flex-direction: column;
  }
}

.orderCardBody {
  flex-direction: column;
  justify-content: space-between;
}

.orderCardHeader {
  margin-bottom: 0.75rem;
}

.orderCardTitle {
  font-size: 1.4rem;
  margin-bottom: 0.25rem;
  color: #46a0d7;
}

.orderCardTitle:hover {
  cursor: pointer;
}

.orderCardPrice {
  font-weight: 900;
}

.orderCardImage {
  width: 22.5%;
}

@media (max-width: 900px) {
  .orderCardImage {
    display: none;
  }
}

.orderCardImage img {
  width: 100%;
  aspect-ratio: 1/1;
  height: auto;
  border-radius: 9px;
  object-fit: cover;
}

.orderCardDetails {
  margin-bottom: 0.5rem;
}

.orderCard button {
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0.5rem;
}

#viewOrderButton {
  background-color: #f7fbfd;
  color: #46a0d7;
  border: 1px solid #46a0d7;
}

#cancelOrderButton {
  background-color: #d64747;
  color: white;
  border: 1px solid #d64747;
}

.orderCardLoader {
  height: 257px;
  border: none;
  animation: loading-keyframes 1s linear infinite alternate;
}
