.browsePage {
  margin: 34px;
  margin-top: 75px;
  display: flex;
  height: calc(100vh - 110px);
}

.filters {
  width: 20%;
  height: 100%;
  margin-right: 20px;
  padding-block: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 25px;
}

.filter {
  margin-bottom: 15px;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter:hover {
  cursor: pointer;
  color: rgba(4, 7, 9, 0.5);
}

.filterCategory {
  width: 100%;
  border-top: 2px solid #78c5f4;
  margin-top: 15px;
  padding-top: 15px;
}

.selectedFilter {
  color: #78c5f4;
}

.selectedFilter:hover {
  color: #78c5f4;
}

.products {
  width: 80%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  padding: 25px;
  padding-bottom: 0;
}

@media (max-width: 900px) {
  .products {
    width: 100%;
    height: 80%;
    padding: 0;
    row-gap: 20px;
    align-content: flex-start;
  }
}

.colorChoicesRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.colorChoice {
  flex: 1 0 30%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.colorChoice:hover {
  cursor: pointer;
}

.colorChoiceCircle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider {
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
  height: 50px;
  position: relative;
}

.track {
  height: 10px;
  background: #d8d8d8;
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid #78c5f4;
}

.track.active {
  background: #78c5f4;
}

.thumb {
  width: 1.5rem;
  height: 1rem;
  padding: 0.4rem;
  background: #46a0d7;
  border-radius: 5px;
  color: #f1f6f9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumb-0 {
  transform: translateY(70%) translateX(-30%);
}

.thumb-1 {
  transform: translateY(70%) translateX(30%);
}

.thumb:hover {
  cursor: pointer;
}

.thumb::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #46a0d7 transparent;
}

.thumb:focus {
  outline: none;
}

.slider .track-0 {
  background: #f0faf3;
  z-index: 1;
}

.slider .track-1 {
  background: #46a0d7;
  z-index: 2;
}

.slider .track-2 {
  background: #f0faf3;
  z-index: 1;
}

.filterToggle {
  width: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 auto;
  margin-bottom: 15px;
  padding-inline: 10px;
  padding-block: 5px;
  border: 1px solid #78c5f4;
  color: #46a0d7;
  border-radius: 20px;
  background-color: #f7fbfd;
  z-index: 1;
}

@media (min-width: 900px) {
  .browsePage {
    flex-direction: row;
    margin-inline: 10rem;
  }
  .filterToggle {
    display: none;
  }
}

@media (max-width: 900px) {
  .filters {
    width: 100%;
    height: 20%;
    margin-right: 0;
    margin-bottom: 20px;
    padding: 0;
  }
  .filtersActive {
    margin-top: -42px;
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: visible;
  }
}

@media (max-width: 900px) {
  .browsePage {
    flex-direction: column;
    height: calc(150vh - 110px);
  }
  .filters {
    display: none;
  }
}

.filtersActive {
  display: block;
}

.productCard.loading {
  height: auto;
  max-height: 400px;
  min-height: 200px;
}
