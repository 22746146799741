.aboutPage {
  margin: 34px;
  margin-top: 120px;
  display: flex;
  width: (100% - 68px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aboutPage h1 {
  align-self: center;
  text-align: center;
  color: #46a0d7;
}

.aboutPage p {
  max-width: 600px;
  line-height: 1.2rem;
}

.aboutPage h2 {
  font-size: 20px;
}

.aboutPage h3 {
  font-size: 18px;
}

.aboutContent {
  justify-content: flex-start;
}

.sizingSection {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}

.sizingSection th {
  padding-inline-end: 20px;
  font-size: 1.1rem;
}

.sizingSection td {
  padding-inline-end: 20px;
  font-size: 1.1rem;
}
