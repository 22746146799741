.header {
  background-color: #f7fbfd;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-height: 75px;
  height: 10vh;
  font-weight: 700;
  color: #46a0d7;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 51;
}

.headerLogo {
  margin-left: 10rem;
  font-size: 48px;
  font-weight: 900;
  cursor: pointer;
}

@media (max-width: 900px) {
  .headerLogo {
    margin-left: 34px;
  }
}

.headerAction {
  margin-right: 10rem;
  position: relative;
}

@media (max-width: 900px) {
  .headerAction {
    margin-right: 34px;
  }
}

.headerCartBadge {
  background-color: #46a0d7;
  color: white;
  border-radius: 50%;
  font-size: 0.7rem;
  position: absolute;
  bottom: -3px;
  right: 0;
  padding: 0.2rem 0.4rem;
}

.headerActionPopout {
  margin-right: 10rem;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #f7fbfd;
  border-radius: 0.5rem;
  width: 25%;
  height: 50%;
  opacity: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow-y: clip;
  min-width: 350px;
  z-index: 53;
  margin-top: 7.6vh;
}

@media (min-width: 325px) and (max-width: 900px) {
  .headerActionPopout {
    margin-inline: auto;
    width: 85%;
    height: 90%;
    min-width: 315px;
  }
}

@media (max-width: 325px) {
  .headerActionPopout {
    margin-right: 5px;
    width: 95%;
    height: 90%;
    min-width: 200px;
  }
}

.privacyScreen {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: end;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.headerActionPopoutSection {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
}

.headerSectionContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 20px;
  color: #040709;
  width: 100%;
}

.headerPopoutSectionTitle {
  font-size: 1.2rem;
  font-weight: 700;
}

.headerPopoutCartItem {
  display: flex;
  flex-direction: row;
  padding-block: 10px;
  height: 30%;
  min-height: 75px;
}

@media (max-width: 900px) {
  .headerPopoutCartItem {
    width: 100%;
  }
}

.headerPopoutSectionIcon:hover {
  cursor: pointer;
}

.headerPopoutClose:hover {
  cursor: pointer;
}

.headerActionIcon:hover {
  cursor: pointer;
}

.headerPopoutEmptyCart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 75px;
  font-weight: 100;
}

.headerPopoutCartItemImg {
  height: 100%;
  aspect-ratio: 1/1;
  width: auto;
  border-radius: 9px;
  object-fit: cover;
}

@media (max-width: 900px) {
  .headerPopoutCartItemImg {
    height: 80%;
  }
}

.headerPopoutCartItemDetails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-inline: 10px;
}

.popoutItemHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerPopoutCartItemPrice {
  color: #46a0d7;
}

.headerPopoutCartItemName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
}

.popoutItemBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 200;
  margin-top: 2%;
}

@media print {
  .header {
    display: none;
  }
}

.headerPopoutCheckoutButton {
  width: 45%;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerPopoutOrdersButton {
  width: 45%;
  font-weight: bold;
  color: #46a0d7;
  background-color: #f7fbfd;
  border: 1px solid #46a0d7;
}

@media (min-width: 900px) and (max-width: 1300px) {
  .headerPopoutOrdersButton {
    padding-block: 6px;
  }
  .headerPopoutCheckoutButton {
    padding-block: 6px;
  }
}

.headerPopoutCartItemModify {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
}

.headerPopoutCartItemModify button {
  background-color: #46a0d7;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 1.2rem;
  margin-top: 0.4rem;
  margin-right: calc(1.2rem - 5px);
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  width: auto;
}

#headerPopoutCartWrapper {
  height: 70%;
}

@media (max-width: 900px) {
  #headerPopoutTopWrapper {
    height: 12%;
  }
  #headerPopoutCartWrapper {
    height: 88%;
  }
}

@media (max-width: 425px) {
  .headerPopoutCartItem img {
    display: none;
  }
}
