.itemUpdatePage {
  position: absolute;
  margin: auto;
  width: 80%;
  height: 100%;
  z-index: 52;
  display: flex;
  flex-direction: column;
}

.itemUpdateFormContent {
  width: 100%;
  height: auto;
}

#itemUpdateMainImage {
  width: 25%;
  height: auto;
}

.itemUpdateFormImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 900px) {
  .itemUpdatePage img {
    width: 50%;
  }
}

.itemUpdateFormImages {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 75%;
}

.itemUpdateThumbnail {
  width: 100px;
  aspect-ratio: 1;
  height: auto;
  object-fit: cover;
}

.itemUpdateClose {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  cursor: pointer;
}
