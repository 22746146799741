.adminPage {
  margin: 34px;
  margin-top: 75px;
  display: flex;
  height: calc(100vh - 110px);
  flex-direction: column;
}

@media (min-width: 900px) {
  .adminPage {
    margin-inline: 10rem;
  }
}

@media (max-width: 900px) {
  .adminPage {
    height: calc(150vh - 110px);
  }
}

.adminContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 9px;
  overflow: hidden;
  border: 1px solid #4ab2f3;
}

.adminSummary {
  margin-bottom: 20px;
  height: calc(55% - 20px);
}

.adminActions {
  height: calc(45% - 20px);
  display: flex;
  flex-direction: row;
}

.adminAction {
  height: 100%;
}

.adminAction + .adminAction {
  margin-left: 20px;
}

.adminPage h1 {
  margin-block: 0;
  font-size: 1.5rem;
}

.adminSummaryContent {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.adminSummaryDataRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  margin-block: auto;
  width: 80%;
}

.adminSummaryDatapoint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.adminSummaryValue {
  font-size: 5rem;
  font-weight: bold;
}

.adminLineItem {
  width: 100%;
  justify-content: flex-start;
}

.adminActionContent {
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
}

.adminActionItemContent {
  margin-left: 10px;
}

@media (max-width: 900px) {
  .adminSummaryDataRow {
    flex-direction: column;
  }
  .adminSummaryDatapoint {
    margin-block: 20px;
  }
  .adminActions {
    flex-direction: column;
    height: 100vh;
  }
  .adminAction + .adminAction {
    margin-top: 20px;
    margin-left: 0px;
  }
}

.adminModifyButton {
  height: 20%;
  display: flex;
  color: white;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  object-fit: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  flex-basis: 25%;
}

.adminModifyButton:hover {
  cursor: pointer;
  color: #46a0d7;
}

.adminModify {
  display: flex;
  flex-flow: wrap;
  row-gap: 0px;
}
