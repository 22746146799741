.productPage {
  margin: 34px;
  margin-top: 75px;
  display: flex;
  height: calc(100vh - 6rem - 34px);
  align-items: flex-start;
  justify-content: center;
  width: calc(100% - 30rem);
}

@media (min-width: 900px) {
  .productPage {
    flex-direction: row;
    margin-inline: 15rem;
    margin-top: 6rem;
  }
}

@media (max-width: 900px) {
  .productPage {
    flex-direction: column;
    width: calc(100% - 68px);
    height: auto;
  }
}

.imageContainer {
  width: 60%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 5px;
}

.productPage .productDetails {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px;
  margin-left: 0px;
  padding: 10px;
  padding-left: 0px;
}

.productImage {
  aspect-ratio: 1/1.2;
  width: 75%;
  height: auto;
  object-fit: contain;
  border-radius: 9px;
  overflow: hidden;
  position: relative;
}

.productImage #productImage {
  aspect-ratio: 1/1.2;
  height: 100%;
  width: auto;
  border-radius: 9px;
  overflow: hidden;
  object-fit: cover;
}

.imageCarousel {
  width: 10%;
  aspect-ratio: 1/1;
  height: 80%;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}

.imageCarousel img {
  width: 100%;
  aspect-ratio: 1/1;
  height: auto;
  object-fit: cover;
  margin-bottom: 0.5rem;
  border-radius: 9px;
  border-radius: 9px;
  overflow: hidden;
}

.imageCarousel img:hover {
  cursor: pointer;
  box-shadow: 0 0 10px #78c5f4;
}

.productPageTitle {
  font-size: 1.7rem;
  margin-bottom: 0.7rem;
  font-weight: bold;
}

.productPageDescription {
  font-size: 1.1rem;
  margin-bottom: 0.7rem;
  color: rgba(4, 7, 9, 0.8);
}

.productPagePrice {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.7rem;
  color: #46a0d7;
}

@media (max-width: 900px) {
  .productPagePrice {
    display: none;
  }
}

@media (max-width: 900px) {
  .productPageTitle span {
    display: inline;
    font-size: 1.4rem;
    padding-left: 10px;
  }
}

@media (max-width: 900px) {
  .productPage .productDetails {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .imageContainer {
    flex-direction: column-reverse;
    width: 100%;
    padding: 0px;
  }
  .imageCarousel {
    flex-direction: row;
    width: 100%;
    height: 15%;
    aspect-ratio: auto;
    margin-right: 0;
    margin-top: 1rem;
  }
  .productImage {
    width: 100%;
    aspect-ratio: 1/1;
  }
  .productImage img {
    width: 100%;
  }
  .imageCarousel img {
    width: 15%;
    aspect-ratio: 1/1;
    height: auto;
    object-fit: cover;
    margin-right: 0.5rem;
    border-radius: 9px;
    overflow: hidden;
  }
}

.carouselSelected {
  box-shadow: 0 0 10px #78c5f4;
}

.sizeSelected {
  border: 2px solid #78c5f4;
  color: #78c5f4;
}

.imageSelector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  position: absolute;
  bottom: 0;
  right: 0;
  padding-block: 10px;
  padding-inline: 10px;
}

.imageSelectorIcon {
  background-color: #f7fbfd;
  color: #a7bac8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-inline: 5px;
}

.imageSelectorIcon:hover {
  cursor: pointer;
  color: #46a0d7;
}

.imageSelectorIcon svg {
  width: 20px;
  height: 20px;
  stroke-width: 2px;
}

.productPageSize {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
}

.productPageSizeOptions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
}

.productPageSizeOption {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  padding-inline: 10px;
  padding-block: 10px;
  border: 1px solid #a7bac8;
  border-radius: 5px;
  color: #a7bac8;
  width: 25%;
}

.productPageSizeOption:hover {
  cursor: pointer;
  color: #46a0d7;
  border: 1px solid #46a0d7;
}

.productPageSizeSelected {
  color: #46a0d7;
  border: 1px solid #46a0d7;
}

.productPageSizeTitle {
  font-weight: bold;
  font-size: 1.1rem;
}

.productPageSizeError {
  color: #d64747;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.sizingGuideLink {
  color: #46a0d7;
}

.sizingGuideLink:hover {
  cursor: pointer;
}
