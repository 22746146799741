@import url("https://use.typekit.net/awp4llq.css");

body {
  margin: 0;
  font-family: "century-gothic", sans-serif, Arial, sans-serif;
  color: #040709;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7fbfd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: #0d1417;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
  background: #46a0d7;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: #46a0d7;
}

button {
  width: 100%;
  padding-inline: 24px;
  padding-block: 12px;
  border-radius: 4px;
  border: none;
  background-color: #46a0d7;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-family: inherit;
}

a {
  text-decoration: none;
  color: #46a0d7;
}

.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #46a0d7;
  z-index: 101;
}

.loading {
  border: none;
  animation: loading-keyframes 1s infinite alternate;
}

@keyframes loading-keyframes {
  0% {
    background-color: #d4e4ed;
  }
  100% {
    background-color: #e2edf3;
  }
}
