@import url("https://use.typekit.net/awp4llq.css");

.checkoutPage {
  margin: 34px;
  margin-top: 75px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: calc(100% - 20rem);
}

@media (min-width: 900px) {
  .checkoutPage {
    flex-direction: row;
    margin-inline: 10rem;
    margin-top: 5rem;
  }
}

@media (max-width: 900px) {
  .checkoutPage {
    flex-direction: column-reverse;
    align-items: center;
    width: calc(100% - 68px);
  }
}

.checkoutDetails {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px;
}

.checkoutSummary {
  width: 30%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px;
}

@media (max-width: 900px) {
  .checkoutSummary {
    width: 100%;
    height: 20%;
    margin-bottom: 20px;
  }
  .checkoutDetails {
    width: 100%;
    height: 80%;
  }
}

.summaryThumbnails {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}

.summaryThumbnails::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, transparent 150px, #f7fbfd);
}

.summaryThumbnail {
  aspect-ratio: 1/1;
  width: 25%;
  height: auto;
  border-radius: 9px;
  object-fit: cover;
}

.summaryTotalSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.summaryTotal {
  width: 100%;
  color: rgba(4, 7, 9, 0.8);
}

.summaryTotalHR {
  width: 100%;
  border-top: 2px solid #78c5f4;
  margin-top: 15px;
  padding-top: 15px;
}

.checkoutDrawer {
  width: 90%;
  border-radius: 9px;
  box-shadow: 0px 0px 10px 0px rgb(120, 197, 244, 0.2);
  margin-block: 20px;
  padding: 20px;
  border: 1px solid #46a0d7;
  background-color: white;
}

@media (max-width: 900px) {
  .checkoutDrawer {
    margin-block: 10px;
  }
}

#organization {
  margin: 0;
  margin-right: 0.8rem;
  height: 1.6rem;
  width: 1.6rem;
  accent-color: #46a0d7;
  background-color: white;
}

#organization:hover {
  cursor: pointer;
}

.organizationSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.organizationSelect label {
  font-size: 1.1rem;
}

.drawerHeader {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.checkoutInput {
  width: 100%;
}

.checkoutPage input {
  margin-top: 5px;
  width: calc(100% - 25px);
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  font-family: "century-gothic", sans-serif;
  font-weight: 200;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease,
    color 0.15s ease;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
  color: #040709;
  font-size: 16px;
}

.checkoutPage input:focus {
  outline: none;
}

.checkoutPageNames {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkoutHeader {
  font-size: 1.2rem;
  font-weight: bold;
  margin-block: 20px;
  display: flex;
  align-items: center;
}

.closedDrawer {
  color: rgb(4, 7, 9, 0.3);
  box-shadow: none;
  border: 1px solid rgb(4, 7, 9, 0.3);
}

.grayscale {
  filter: grayscale(100%);
  opacity: 50%;
}

.checkoutPaymentThumbnails {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.checkoutPaymentThumbnail {
  width: 34px;
  height: 24px;
  margin-right: 10px;
}

.checkoutSummary span {
  font-weight: bold;
}

.Label {
  font-family: "Century Gothic", sans-serif;
}

.checkoutButton {
  margin-top: 0.75rem;
}

.disabled {
  display: none;
}

.checkoutPage form {
  display: flex;
  width: 100%;
  justify-content: center;
}

#checkoutDiscountLoad svg {
  width: 20px;
  height: 20px;
  align-self: center;
}

.discountSection {
  margin-bottom: 10px;
}

.checkoutBackupButton {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.checkoutBackupButton button {
  width: 80%;
}

.adblockPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.adblockPopup h1 {
  margin-bottom: 0;
}

.adblockPopupText {
  margin-bottom: 10px;
  background-color: #f7fbfd;
  padding: 10px;
  padding-inline: 20px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
}

.adblockPopupText p {
  font-size: 1.2rem;
}

.adblockPopupText p + p {
  margin-top: 10px;
}

.adblockPopupText h1 {
  font-size: 1.5rem;
  font-weight: bold;
  align-self: center;
  justify-content: center;
}

.adblockPopupButton {
  margin-bottom: 10px;
}

.adblockPopupButtonSecondary {
  background-color: #f7fbfd;
  color: #46a0d7;
  border: 1px solid #46a0d7;
}

.checkoutLoading {
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkoutOrgContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkoutOrgContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: rgba(4, 7, 9, 0.1);
  border-radius: 3px;
}

.checkoutOrgContainer:hover input ~ .checkmark {
  background-color: rgba(4, 7, 9, 0.2);
}

.checkoutOrgContainer input:checked ~ .checkmark {
  background-color: #46a0d7;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkoutOrgContainer input:checked ~ .checkmark:after {
  display: block;
}

.checkoutOrgContainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#checkoutPolicies {
  justify-content: space-between;
}

.checkoutLink + .checkoutLink {
  margin-left: 15px;
  text-align: end;
}
