.homePage {
  margin: 34px;
  margin-top: 75px;
  display: flex;
  height: calc(100vh - 110px);
}

@media (min-width: 900px) {
  .homePage {
    flex-direction: row;
    margin-inline: 10rem;
  }
}

@media (max-width: 900px) {
  .homePage {
    flex-direction: column;
    height: calc(150vh - 110px);
  }
}

.largeHomeCard {
  border-radius: 9px;
  overflow: hidden;
  width: 55vw;
  height: 45%;
  background-image: linear-gradient(
      0deg,
      rgba(4, 7, 9, 1) 0%,
      rgba(4, 7, 9, 0) 50%
    ),
    url("https://images.squarespace-cdn.com/content/v1/5ada446c70e80229f1aec2d9/1524691207874-2M6F2K901YWC7SGRN6EQ/about+naimun.jpg?format=2500w");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.largeHomeCard:hover {
  cursor: pointer;
  transition: transform 0.2s;
}

@media (min-width: 900px) {
  .largeHomeCard {
    width: 55vw;
    height: 95%;
  }
}

@media (max-width: 900px) {
  .largeHomeCard {
    width: 100%;
    height: 30%;
  }
}

.largeHomeCardText {
  color: white;
  text-align: left;
  padding: 20px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
}

.largeHomeCardTitle {
  font-weight: 700;
  margin-bottom: 10px;
}

@media (min-width: 900px) {
  .largeHomeCardTitle {
    font-size: 220%;
  }
}

@media (max-width: 900px) {
  .largeHomeCardTitle {
    font-size: 125%;
    font-weight: 700;
  }
}

@media (min-width: 900px) {
  .largeHomeCardSubtitle {
    font-size: 120%;
  }
}

@media (max-width: 900px) {
  .largeHomeCardSubtitle {
    font-size: 90%;
  }
}

.homeColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 900px) {
}
.homeColumn {
  width: 45vw;
  max-width: 40%;
  height: 95%;
  padding-left: 20px;
}

@media (max-width: 900px) {
  .homeColumn {
    width: 100%;
    height: calc(60% - 20px);
    padding-left: 0;
    padding-top: 10px;
    max-width: 100%;
  }
}

.smallHomeCard {
  border-radius: 9px;
  background-color: #78c5f4;
  width: 100%;
  height: 50%;
}

.smallHomeCard:hover {
  cursor: pointer;
  transition: transform 0.2s;
}

.smallHomeCard + .smallHomeCard {
  margin-top: 10px;
}

.smallHomeCardAction {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: calc(70% - 50px);
  font-weight: 700;
  font-size: 90%;
}

.smallHomeCardText {
  border-top: 1px solid #040709;
  padding: 20px;
  padding-bottom: 80px;
  height: calc(20% - 80px);
  font-size: 2vh;
}

@media (max-width: 900px) {
  .smallHomeCardText {
    padding: 10px;
  }
}

@media (min-width: 900px) {
  .smallHomeCardAction {
    font-size: 200%;
  }
}

@media (max-width: 900px) {
  .smallHomeCardAction {
    font-size: 200%;
  }
}

@media screen and (min-width: 900px) {
  .smallHomeCardText {
    font-size: calc(40% + 5px);
  }
}

@media screen and (min-width: 1065px) {
  .smallHomeCardText {
    font-size: calc(60% + 5px);
  }
}
