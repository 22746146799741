.embla {
  max-width: 48rem;
  margin: auto;
  --slide-height: 100%;
  --slide-spacing: 1rem;
  --slide-size: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.embla__viewport {
  overflow: hidden;
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 9px;
}

.embla__viewport:hover {
  cursor: pointer;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
  width: calc(100% + var(--slide-spacing) * 2);
  height: 100%;
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
  backdrop-filter: blur(5rem);
}
.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  position: absolute;
  bottom: 10%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(70, 160, 215, 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem #fff;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: "";
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem #46a0d7;
}

.embla img {
  height: 100%;
  width: auto;
  object-fit: cover;
}
