.codePage {
  margin: 34px;
  margin-top: 75px;
  display: flex;
  height: calc(100vh - 110px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.codePageDescription {
  width: 30%;
  text-align: center;
  font-size: 1.4rem;
  margin-top: 20px;
}

.codePageActions {
  width: 30%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 300px;
}

.codePageActions button {
  width: 48%;
}

.codePageActions button:only-child {
  width: 100%;
}

.codePageActions img {
  width: 48%;
}

@media print {
  .codePageActions {
    display: none;
  }
  .header {
    display: none;
  }
  .codePageDescription {
    display: none;
  }
  #qrCode {
    width: 100%;
  }
  .codePage {
    margin: 0;
    height: 100vh;
  }
}

@media (min-width: 900px) {
  .codePage {
    margin-inline: 10rem;
  }
}

@media (max-width: 900px) {
  .codePage {
    height: auto;
  }
  .codePageDescription {
    width: 80%;
  }
  .codePageActions {
    width: 80%;
    flex-direction: column;
    align-items: center;
  }
  .codePageActions button {
    width: 100%;
    margin-top: 10px;
  }
}
