.productCard {
  display: flex;
  flex-direction: column;
  flex: 1 1 30%;
  max-width: 30%;
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 10px;
  margin-top: 0;
  padding-bottom: 10px;
  height: fit-content;
}

@media (min-width: 673px) and (max-width: 900px) {
  .productCard {
    max-width: 30%;
    margin-bottom: 0;
  }
}

@media (max-width: 672px) {
  .productCard {
    max-width: 43%;
    margin-bottom: 0;
  }
}

.productCard:hover {
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.productCard img {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.productTitle {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 5px;
}

.productDescription {
  font-size: 0.8rem;
  color: rgba(4, 7, 9, 0.8);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-block: 3px;
}

.productPrice {
  font-size: 1rem;
  font-weight: 700;
  color: #46a0d7;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.productDetails {
  padding: 5px;
}
