.orderPage {
  margin: 34px;
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(100% - 22rem);
}

@media (min-width: 900px) {
  .orderPage {
    margin-inline: 11rem;
    margin-top: 6rem;
  }
}

@media (max-width: 900px) {
  .orderPage {
    width: calc(100% - 68px);
  }
}

.orderActionContainer {
  width: 18%;
}

@media (max-width: 768px) {
  .orderActionContainer {
    width: 90%;
  }
}

.orderPageTitle {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.orderPage button {
  margin-top: 1rem;
}

.signOutButton {
  background-color: #d64747;
}

.orderPageHeader {
  font-weight: bold;
}

.orderList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#signOutButton {
  background-color: #d64747;
  font-weight: bold;
  color: white;
  width: 20%;
  align-self: center;
}

#signOutButton:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  #signOutButton {
    width: 100%;
  }
}

.emptyOrdersContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
