.detailsPage {
  margin: 34px;
  margin-top: 75px;
  display: flex;
  height: calc(100vh - 110px);
  width: calc(100% - 68px);
}

@media (min-width: 900px) {
  .detailsPage {
    flex-direction: row;
    margin-inline: 10rem;
    width: calc(100% - 20rem);
  }
}

@media (max-width: 900px) {
  .detailsPage {
    flex-direction: column;
    height: calc(150vh - 110px);
  }
}

.detailsPageContent {
  width: 100%;
}

.orderDetailsCard {
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 0px 0px 10px 0px rgb(120, 197, 244, 0.2);
  border: 1px solid #46a0d7;
  border-radius: 9px;
  font-size: 1.2rem;
}

.orderDetailsCardText {
  display: flex;
}

.orderCardLineItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 15px);
  margin-bottom: 0.5rem;
  max-height: 100px;
  border-radius: 9px;
  padding-right: 15px;
}

.orderCardLineItem:nth-child(odd) {
  background-color: #eff7fb;
}

.orderCardTotalLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 15px);
  margin-bottom: 0.5rem;
  max-height: 100px;
  border-radius: 9px;
  padding-block: 10px;
  padding-right: 40px;
}

.orderDetailsCardImage {
  height: 100%;
  aspect-ratio: 1/1;
  width: 100px;
  position: relative;
}

.orderDetailsCardImage img {
  height: 100%;
  aspect-ratio: 1/1;
  width: auto;
  border-radius: 9px;
  object-fit: cover;
}

.orderCardLineTitle {
  width: 100px;
  height: 100%;
  text-align: center;
}

@media (max-width: 900px) {
  h1 {
    font-size: 1.5rem;
  }
  .orderDetailsCard {
    width: auto;
  }
}

.orderDetailsCardSize {
  position: absolute;
  z-index: 1;
  font-size: 1.6rem;
  top: 50%;
  right: -50%;
  transform: translate(-100%, -50%);
}

.orderDetailsHideable {
  display: flex;
}

@media (max-width: 900px) {
  .orderDetailsHideable {
    display: none;
  }
}

.detailsPageContent h2 {
  color: #fff;
  background-color: #eea95f;
  width: fit-content;
  padding: 5px;
  padding-inline: 10px;
  border-radius: 9px;
}
