.loginPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loginForm {
  width: 18%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1100px) {
  .loginForm {
    width: 70%;
  }
}

.loginPageTitle {
  font-size: 1.7rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.loginFormLabel {
  font-size: 0.7rem;
  background-color: #e6ecf0;
  align-self: flex-start;
  padding: 0.2rem 0.5rem;
  border-radius: 4px 4px 0 0;
  font-weight: 200;
  margin-bottom: -0.2rem;
  z-index: 1;
}

.loginFormInput {
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 0.6rem;
  border-radius: 4px;
  border: none;
  background-color: #e6ecf0;
  font-family: inherit;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.loginFormInput:focus {
  outline: none;
  box-shadow: 0 0 0 0.1rem rgba(70, 160, 215, 0.5);
  transition: 0.2s;
}

.loginFormButton {
  width: 100%;
  padding-inline: 24px;
  padding-block: 12px;
  border-radius: 4px;
  border: none;
  background-color: #46a0d7;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-family: inherit;
}

.loginFormButton:hover {
  box-shadow: 0 0 0 0.1rem rgba(70, 160, 215, 0.5);
  transition: 0.2s;
}

.loginFormForgotPassword {
  font-size: 0.8rem;
  margin-bottom: 0.8rem;
  margin-top: 0.3rem;
  color: #46a0d7;
  font-weight: bold;
  cursor: pointer;
  align-self: flex-start;
}

.loginFormForgotPassword:hover {
  text-decoration: underline;
}

.loginFormError {
  color: #d64747;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.loginFormRegister {
  font-size: 0.8rem;
  margin-top: 0.8rem;
  align-self: flex-start;
}

.loginFormRegisterLink {
  color: #46a0d7;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.loginFormRegisterLink:hover {
  text-decoration: underline;
}

.loginFormDivider {
  font-size: 0.8rem;
  margin-block: 1.3rem;
  display: flex;
  align-items: center;
}

.loginAltButton {
  background-color: #e6ecf0;
  color: #040709;
  margin-bottom: 0.8rem;
  display: inline-block;
  justify-content: center;
  align-items: center;
}

.loginFormButtonIcon {
  float: left;
}
